html {
  min-width: 100vh;
  min-height: 100vh;
}

body {
  font-size: 16px;
  min-width: 100vh;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  width: 100%;
  height: 100%;
}