* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.landing {
  background: radial-gradient(at 50% 650px, #3869b4, #031f4b);
  min-height: 100vh;
  font-size: 1em;
  overflow: hidden;
}

.titleBox {
  position: absolute;
  top: 20%;
  left: 51%;
  transform: translate(-50%, -50%);
  font-size: 1.875em;
  color: rgb(215, 218, 222);
  font-family: "Poppins", sans-serif;
  white-space: nowrap;
}

.world {
  position: fixed;
  display: block;
  margin: auto;
  top: 63%;
  right: 50%;
  transform: translate(50%, -50%);
  width: 25em;
  height: 25em;
  border-radius: 50%;
  background: url(../../media/world.png);
  background-size: cover;
  box-shadow: inset 0 0 1.25em #000, inset -2.5em -0.625em 1.25em rgba(3, 32, 56, 0.7);
  animation: world 5s linear infinite;
}

@keyframes world {
  0% {
    background-position: 0;
  }
  100% {
    background-position: -800px;
  }
}

.img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 15.625em;
  height: 15.625em;
  opacity: 0.12;
  transform: rotate(10deg);
  transform: translate(-77%, -72%);
}

.button {
  font-size: 2em;
  font-family: "Poppins", sans-serif;
  padding: .5em .5em;
  -webkit-appearance: none;
  appearance: none;
  background-color: #114868c5;
  color: rgb(215, 218, 222);
  border-radius: 0.9375em;
  border: none;
  cursor: pointer;
  position: absolute;
  box-shadow: 0 2px 25px rgba(139, 241, 241, 0.5);
  top: 32%;
  left: 50%;
  transform: translate(-50%, -50%);

}

.button:hover {
  background-color: #347195c5;
  transition: background 0.6s;
}

@media (max-width: 1600px) {
  body{
    font-size: 14px;
  }
}

@media (max-width: 992px) {
  body {
    font-size: 12px;
  }
}

@media (max-width: 768px) {
  body {
    font-size: 10px;
  }
}

@media (max-width: 576px) {
  body {
    font-size: 8px;
  }
}