.formContainer{
  width: 43.75em;
  min-height: 50em;
}

.allForm{
  width: 43.75em;
  min-height: 50em;
  position: relative;
}

.contentForm{
  position: absolute;
  width: 43.75em;
  min-height: 50em;
  display: flex;
  padding-left: 1.875em;
  flex-direction: column;
  align-items:left;
  justify-content: space-between;
}

.label{
  font-family: "Poppins", sans-serif;
  padding-right: 1.25em;
  font-weight: 600;
}

.inputText{
  width: 12.5em;
  height: 1.875em;
  font-size: 1em;
  text-indent: 0.4375em;
  font-family: "Poppins", sans-serif;
}

.checkBoxes{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 95%;
  height: 3.125em;
  font-family: "Poppins", sans-serif;
}

.checkBoxes span{
  margin-left: 0.625em;
}

.checkBox{
  cursor: pointer;
  width: 1.25em;
  height: 1.25em;
  transform: translate(0%, 20%);
}

.countries {
  display: flex;
  flex-direction: row;
  justify-content: left;
  transition-delay: .3s ease all;
}

.country{
  margin-right: 1.25em;
  background-color: rgb(215, 218, 222);
  color: #fff;
  text-align: center;
  border-bottom-left-radius: 1.25em;
  border-bottom-right-radius: 1.25em;
  border: 0.125em solid rgb(0, 0, 0);
}

.countryImg{
  width: 100%;
  height: 3.75em;
  object-fit: cover;
  border-bottom: 0.125em solid rgb(0, 0, 0);
}

.countryName{
  height: 1.5625em;
  width: 6.25em;
  text-overflow: ellipsis;
  overflow:hidden;
  white-space: nowrap;
  color: black;
}

.countryBtn {
  width: 1.875em;
  height: 1.875em;
  border: none;
  background: none;
  cursor: pointer;
  transition: .3s ease all;
  border-radius: 0.3125em;
  color: #e91d1d;
}

.countryBtn:hover {
  background: #8b8b8b;
}

.error{
  color: #e91d1d ;
  padding-top: 0.3125em;
  font-family: "Poppins", sans-serif;
  font-size: 0.9375em;
}

.labelCoun{
  padding-bottom: 0.625em;
  font-family: "Poppins", sans-serif;
}

.select{
  height: 12.5em;
  margin-bottom: 1.25em;
  margin-top: 0.9375em;
  font-family: "Poppins", sans-serif;
  font-size: 0.8em;
  cursor: pointer;
}

.optionSelect{
  text-indent: 10px;
}

.spanRange{
  margin-left: 0.625em;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}

.inputRange{
  transform: translate(0%, 20%);
  cursor: pointer;
}

.inputNumber {
  height: 1.875em;
  width: 3.125em;
  text-indent: 0.4375em;
  font-family: "Poppins", sans-serif;
}

.btnSubmit{
  background: #24407d;
  font-size: 1.125em;
  font-family: "Poppins", sans-serif;
  color: rgb(215, 218, 222);
  width: 9.375em;
  height: 2.625em;
  border-radius: 0.75em;
  border: 1px solid silver;
  cursor: pointer;
  margin-left: 13.125em;
  margin-bottom: 3.125em;
}

.btnSubmit:disabled {
  background: #9b9c9f;
  cursor: not-allowed;
  color: #e91d1da6;
}