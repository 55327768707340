.allNav{
  position: relative;
  display: grid;
  grid-template-columns: minmax(330px,1fr) repeat(9, 1fr);
  align-items: center;
  background-color: rgb(80, 118, 153);
  min-height: 9.375em;
  border-bottom: #000000 2px solid;
}

.filtersContainer {
  grid-column: 4 / 6;
  position: relative;
}

.logo{
  position: absolute;
  width: 7.5em;
  height: 6.25em;
  left : 5em;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.titleCont {
  position: absolute;
  left: 14.375em;
  transform: translate(-50%, -50%);
}

.title {
  color: rgb(215, 218, 222);
  font-family: "Poppins", sans-serif;
}

.searchBarCont{
  grid-column: 3 / 4;
  justify-self: center;
  align-self: center;
}

.creaAct{
  color: rgb(215, 218, 222);
  font-size: 1.25em;
  font-family: "Poppins", sans-serif;
  position: relative;
  padding: 1.375em;
  padding-bottom: 3.125em;
  grid-column: 5 / 6;
  width: 250px;
}

.labelAct{
  position:absolute;
  left: 50%;
  white-space: nowrap;
  transform: translate(-50%, -70%);
}

.btnAct{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 0);
  background: #24407d;
  font-size: 1.125em;
  font-family: "Poppins", sans-serif;
  color: rgb(215, 218, 222);
  width: 5em;
  height: 2.625em;
  border-radius: 0.75em;
  border: 1px solid silver;
  cursor: pointer;
}

.btnAct:hover {
  background: #5d6c8f;
  transition: background 0.6s;
}