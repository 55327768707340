.container {
  background-color:rgb(215, 218, 222);
  color: #000;
  width: 13.125em;
  height: 15.625em;
  text-align: center;
  border-bottom-left-radius: 1.25em;
  border-bottom-right-radius: 1.25em;
  transition: background-color 0.5s, transform 0.5s;
  border: 2px solid rgb(0, 0, 0);
  position: relative;
  font-family: "Poppins", sans-serif; 
}

.container:hover {
  cursor: pointer;
  transform: scale(1.15);
  background-color: rgb(80, 118, 153);
}

.container h4 {
  margin: 0;
}

.imgContainer img {
  width: 100%;
  height: 8.125em;
  object-fit: cover;
  border-bottom: 2px solid rgb(0, 0, 0);
}

.continent{
  bottom: 10px;
  left: 50%;
  position: absolute;
  transform: translate(-50%, 0);
}

.continentName {
  white-space: nowrap;
}

.nameCountry{
  font-size: 1.2em;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 10px;
  padding-top: 0%;
}

a {
  text-decoration: none;
}