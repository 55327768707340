.overlay{
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0,0,0,.5);
  padding: 2.5em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.back{
  width: 100vw;
  height: 100vh;
  position: relative;
  z-index: 20;
  top: 0;
  left: 0;
}

.back:hover{
  cursor: pointer;
}

.contenedorModal{
  z-index: 60;
  width: 43.75em;
  min-height: 25em;
  max-height: 870px;
  background: #fff;
  position: absolute;
  border-radius: 0.3125em;
  box-shadow: rgba(100,100,111, 0.2) 0px 7px 29px 0px;
  padding: 1.25em;
}

.encabezadoModal{
  display: flex;
  align-items: center;
  justify-content: center ;
  margin-bottom: 1.25em;
  padding-bottom: 1.25em;
  border-bottom: 1px solid #e8e8e8;
  font-family: "Poppins", sans-serif;
  color: #1766dc;
  font-size: 1.25em;
}

.botonCerrar{
  position: absolute;
  top: 0.9375em;
  right: 1.25em;
  width: 1.875em;
  height: 1.875em;
  border: none;
  background: none;
  cursor: pointer;
  transition: .3s ease all;
  border-radius: 0.3125em;
  color: #1766dc;
  
}

.botonCerrar:hover {
  background: #f2f2f2;
}

.botonCerrar svg{
  width: 100%;
  height: 100%;
}